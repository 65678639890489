import accounting from 'accounting';

export const formatMoney = (number: string, precision = 2) =>
  accounting.formatMoney(number, {
    symbol: '',
    precision,
    thousand: '.',
    decimal: ',',
  });

export const unformat = (numberString: string, separator = ',') =>
  accounting.unformat(numberString, separator);

export const roundAndFormatNumber = (
  value: string | number,
  precision?: number,
) => formatMoney(value as string, precision);
