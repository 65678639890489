import React, {ForwardedRef, forwardRef} from 'react';
import {
  Input,
  InputLabel,
  InputAdornment,
  FormHelperText,
  Card,
  CardContent,
  Button,
  FormControl,
  LinearProgress,
} from '@material-ui/core';

import {
  Formik,
  Form,
  Field,
  FormikProps,
  FormikHelpers,
  FieldProps,
} from 'formik';

import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';

import {useTranslation} from 'react-i18next';

import {SignupSchema1} from './formValidation';
import {maskCpfCnpj} from '../auth/utils';

interface FormValues {
  cnpj: string;
}

type Props = {
  loading: boolean;
  handleSubmitForm: (values: FormValues) => void;
};

export const Form1 = forwardRef(
  (
    {loading, handleSubmitForm}: Props,
    ref: ForwardedRef<FormikProps<FormValues>>,
  ) => {
    const {t} = useTranslation();
    return (
      <Formik
        innerRef={ref}
        enableReinitialize={false}
        initialValues={{
          cnpj: '',
        }}
        validationSchema={SignupSchema1(t)}
        validateOnMount={false}
        onSubmit={(
          values: FormValues,
          {setSubmitting}: FormikHelpers<FormValues>,
        ) => {
          setSubmitting(true);

          handleSubmitForm(values);
        }}>
        {({
          isSubmitting,
          isValidating,
          touched,
          setFieldValue,
          handleBlur,
          values,
          errors,
        }) => {
          return (
            <Card className="m-0 w-100 p-0 border-0">
              <div className="card-header d-block p-3 mx-2 mb-0 mt-2 rounded border-0" />
              <CardContent className="p-3">
                <div className="text-center text-black-50 mb-3">
                  <span>{t('auth.forgot')}</span>
                </div>

                <Form noValidate autoComplete="off" className="px-5">
                  <div className="mb-3">
                    <FormControl className="w-100">
                      <InputLabel htmlFor="auth-reset-cnpj">
                        {t('auth.cpfCnpj')}
                      </InputLabel>
                      <Field name="cnpj">
                        {({field}: FieldProps) => (
                          <>
                            <Input
                              autoComplete="off"
                              value={values.cnpj || ''}
                              error={!!errors.cnpj && !!touched.cnpj}
                              onChange={(e) => {
                                setFieldValue(
                                  'cnpj',
                                  maskCpfCnpj(e.target.value),
                                );
                              }}
                              onBlur={handleBlur}
                              name={field.name}
                              fullWidth
                              id="auth-reset-cnpj"
                              startAdornment={
                                <InputAdornment position="start">
                                  <PersonOutlineOutlinedIcon />
                                </InputAdornment>
                              }
                              inputProps={{
                                maxLength: 18,
                              }}
                            />
                            {!!errors.cnpj && !!touched.cnpj ? (
                              <FormHelperText error>
                                {errors.cnpj}
                              </FormHelperText>
                            ) : null}
                          </>
                        )}
                      </Field>
                    </FormControl>
                  </div>

                  <div className="mb-3 text-center">
                    <Button
                      disabled={isSubmitting || isValidating || loading}
                      type="submit"
                      color="primary"
                      variant="contained"
                      size="large"
                      className="my-2"
                      style={{minWidth: '120px'}}>
                      {t('actions.send')}
                    </Button>
                  </div>

                  <div className="mb-3 py-2" style={{minHeight: '20px'}}>
                    {loading ? (
                      <LinearProgress value={100} variant="indeterminate" />
                    ) : null}
                  </div>
                </Form>
              </CardContent>
            </Card>
          );
        }}
      </Formik>
    );
  },
);
Form1.displayName = 'Form1';
