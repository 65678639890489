import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import {Link} from 'react-router-dom';

import {IconButton, Box} from '@material-ui/core';

import {connect} from 'react-redux';

import projectLogo from 'assets/images/profile.png';

const HeaderLogo = ({sidebarToggle, sidebarHover}) => {
  return (
    <>
      <div
        className={clsx('app-header-logo', {
          'app-header-logo-close': sidebarToggle,
          'app-header-logo-open': sidebarHover,
        })}>
        <Box className="header-logo-wrapper" title="Portal de Pagamentos">
          <Link to="/" className="header-logo-wrapper-link">
            <IconButton
              color="primary"
              size="medium"
              className="header-logo-wrapper-btn">
              <img
                className="app-header-logo-img"
                alt="Portal de Pagamentos"
                src={projectLogo}
              />
            </IconButton>
          </Link>
          <Box className="header-logo-text">Desbravador</Box>
        </Box>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarToggle: state.theme.sidebarToggle,
  sidebarHover: state.theme.sidebarHover,
});

export default connect(mapStateToProps)(HeaderLogo);

HeaderLogo.propTypes = {
  sidebarToggle: PropTypes.bool.isRequired,
  sidebarHover: PropTypes.bool.isRequired,
};
