import React, {FC, useCallback, useRef, useState} from 'react';

import {Grid, Container, Button} from '@material-ui/core';

import {NavLink as RouterLink, useHistory} from 'react-router-dom';
import {useSnackbar} from 'notistack';

import {FormikProps} from 'formik';

import {FaArrowLeft} from 'react-icons/fa';

import LogoDsl from 'assets/images/logo-dsl.png';

import {useTranslation} from 'react-i18next';

import api, {AxiosError, AxiosResponse} from 'services/apiDslService';

import {Form1} from './Form1';
import {Form2} from './Form2';

interface FormValues {
  cnpj: string;
}

interface FormValuesReset {
  cnpjConfirm: string;
  tmpPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
}

export const PasswordReset: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);
  const [cnpjActive, setCnpjActive] = useState<string>('');
  const formikRef1 = useRef<FormikProps<FormValues>>(null);
  const formikRef2 = useRef<FormikProps<FormValuesReset>>(null);
  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation();
  const history = useHistory();

  const handleSubmitForm1 = useCallback(
    (values: FormValues) => {
      setLoading(true);

      api
        .get(`/getpassword/index.php?cnpj=${values.cnpj}`)
        .then((response: AxiosResponse) => {
          setLoading(false);
          formikRef1.current?.setSubmitting(false);

          enqueueSnackbar(response?.data?.msg || t('auth.verifyYourEmail'), {
            variant: 'info',
          });

          setCnpjActive(values.cnpj);
          setStep(2);
        })
        .catch((e) => {
          setLoading(false);
          formikRef1.current?.resetForm();

          const err = e as AxiosError;
          const errResponse = err?.response as AxiosResponse;
          enqueueSnackbar(errResponse?.data?.msg || t('error.error'), {
            variant: 'error',
          });
        });
    },
    [t, enqueueSnackbar],
  );

  const handleSubmitForm2 = useCallback(
    (values: FormValuesReset) => {
      setLoading(true);

      api
        .post('/setpassword/index.php', {
          cnpj: values.cnpjConfirm,
          tmpPassword: values.tmpPassword,
          newPassword: values.newPassword,
        })
        .then((response: AxiosResponse) => {
          setLoading(false);
          formikRef2.current?.setSubmitting(false);

          enqueueSnackbar(
            response?.data?.msg || t('auth.passwordResetSuccess'),
            {
              variant: 'success',
            },
          );

          setCnpjActive('');
          setStep(1);

          history.replace('/auth');
        })
        .catch((e) => {
          setLoading(false);
          formikRef2.current?.setSubmitting(false);
          formikRef2.current?.setFieldValue('tmpPassword', '');
          formikRef2.current?.setFieldValue('newPassword', '');

          const err = e as AxiosError;
          const errResponse = err?.response as AxiosResponse;
          enqueueSnackbar(errResponse?.data?.msg || t('error.error'), {
            variant: 'error',
          });
        });
    },
    [t, enqueueSnackbar, history],
  );

  return (
    <div className="app-wrapper min-vh-100">
      <div className="app-main flex-column">
        <Button
          size="large"
          variant="outlined"
          color="primary"
          component={RouterLink}
          className="btn-go-back"
          to="/auth">
          <span className="btn-wrapper--icon">
            <FaArrowLeft />
          </span>
          <span className="btn-wrapper--label">{t('auth.back')}</span>
        </Button>
        <div className="app-content p-0">
          <div className="app-content--inner d-flex align-items-center">
            <div className="flex-grow-1 w-100 d-flex align-items-center">
              <div className="bg-composed-wrapper--content py-5">
                <Container maxWidth="lg">
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      className="d-flex align-items-center">
                      <img
                        alt="logo dsl"
                        className="w-50 mx-auto d-block img-fluid"
                        src={LogoDsl}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={8}
                      className="d-flex flex-column align-items-center">
                      {step === 1 ? (
                        <Form1
                          ref={formikRef1}
                          handleSubmitForm={handleSubmitForm1}
                          loading={loading}
                        />
                      ) : (
                        <Form2
                          cnpjActive={cnpjActive}
                          ref={formikRef2}
                          handleSubmitForm={handleSubmitForm2}
                          loading={loading}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
