/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, {useEffect, useState, useMemo} from 'react';

import ReactGA4 from 'react-ga4';

import {useLocation} from 'react-router-dom';

const isDev = process.env.NODE_ENV === 'development';

const AnalyticsListener = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log('PATH LOCATION:', location.pathname);

    if (!isDev) {
      ReactGA4.send({
        hitType: 'pageview',

        page: location.pathname,
      });
    }
  }, [location.pathname]);

  return null;
};

export const Tracker = () => {
  const [initialized, setInitialized] = useState(false);

  const trackers = useMemo(() => {
    const trackersV4 = [
      {
        trackingId: 'G-B6HF434MHL',
        gaOptions: {
          name: 'portal_cliente',
        },
      },
    ];

    return {trackersV4};
  }, []);

  useEffect(() => {
    if (!isDev) {
      ReactGA4.initialize(trackers.trackersV4, {
        testMode: isDev,
      });
    }

    setInitialized(true);
  }, [trackers]);

  if (!initialized) {
    return null;
  }

  return <AnalyticsListener />;
};
