/* eslint-disable no-console */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import {useSnackbar} from 'notistack';
import {useTranslation} from 'react-i18next';

import {OverlayLoading} from 'components/overlayLoading';

import api, {
  isAxiosError,
  AxiosError,
  AxiosResponse,
} from 'services/apiDslService';

interface User {
  id: number;
  nome: string;
  documento: string;
  fantasia: string;
}

interface FormValues {
  cnpj: string;
  password: string;
}

interface AuthRequest {
  username: string;
  password: string;
}
interface AuthResponse {
  token: string;
  usuario: User;
  branches: User[];
}

interface AuthContextData {
  signed: boolean;
  user: User | null;
  companies: User[];
  companyActive: User | null;
  loading: boolean;
  signIn(values: FormValues): Promise<void>;
  signOut(): Promise<void>;
  handleChangeCompanyActive(user: User): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({children}) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [companies, setCompanies] = useState<User[]>([]);
  const [companyActive, setCompanyActive] = useState<User | null>(null);
  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation();

  useEffect(() => {
    async function loadStorageData() {
      const storagedUser = window.localStorage.getItem(
        `@DSL_PAYMENT_PORTAL:user`,
      );
      const storagedToken = window.localStorage.getItem(
        `@DSL_PAYMENT_PORTAL:token`,
      );
      const storagedCompanies = window.localStorage.getItem(
        `@DSL_PAYMENT_PORTAL:companies`,
      );
      const storagedCompanyActive = window.localStorage.getItem(
        `@DSL_PAYMENT_PORTAL:companyActive`,
      );

      if (
        storagedUser &&
        storagedToken &&
        storagedCompanies &&
        storagedCompanyActive
      ) {
        setUser(JSON.parse(storagedUser));
        setCompanies(JSON.parse(storagedCompanies));
        setCompanyActive(JSON.parse(storagedCompanyActive));
        api.defaults.headers.common.Authorization = `Bearer ${storagedToken}`;
      }

      setInitialized(true);
    }

    loadStorageData();
  }, []);

  const signOut = useCallback(async () => {
    window.localStorage.removeItem(`@DSL_PAYMENT_PORTAL:user`);
    window.localStorage.removeItem(`@DSL_PAYMENT_PORTAL:token`);
    window.localStorage.removeItem(`@DSL_PAYMENT_PORTAL:companies`);
    window.localStorage.removeItem(`@DSL_PAYMENT_PORTAL:companyActive`);

    delete api.defaults.headers.common.Authorization;

    setUser(null);
    setCompanies([]);
    setCompanyActive(null);
  }, []);

  async function signIn(values: FormValues) {
    setLoading(true);

    const postData: AuthRequest = {
      username: String(values.cnpj).replace(/\D/g, ''),
      password: String(values.password).trim(),
    };

    await api
      .post('/login/', postData)
      .then((response: AxiosResponse<AuthResponse>) => {
        const {usuario, token, branches} = response.data;

        setUser(usuario);
        setCompanies(branches);
        setCompanyActive(usuario);

        api.defaults.headers.common.Authorization = `Bearer ${token}`;

        window.localStorage.setItem(
          '@DSL_PAYMENT_PORTAL:user',
          JSON.stringify(usuario),
        );
        window.localStorage.setItem('@DSL_PAYMENT_PORTAL:token', token);
        window.localStorage.setItem(
          '@DSL_PAYMENT_PORTAL:companies',
          JSON.stringify(branches),
        );
        window.localStorage.setItem(
          '@DSL_PAYMENT_PORTAL:companyActive',
          JSON.stringify(usuario),
        );

        setLoading(false);

        return Promise.resolve();
      })
      .catch((e) => {
        const err = e as AxiosError;
        setLoading(false);

        if (isAxiosError(e)) {
          if (err.response) {
            const errResponse = err.response as AxiosResponse;
            const statusError = errResponse?.status;

            if (statusError === 401) {
              enqueueSnackbar(
                errResponse?.data?.msg || t('auth.invalidCredentials'),
                {
                  variant: 'error',
                },
              );
            }
          }
        } else {
          enqueueSnackbar(t('auth.authError'), {
            variant: 'error',
          });
        }
        return Promise.reject(e);
      });
  }

  const handleChangeCompanyActive = useCallback((value: User): void => {
    setCompanyActive(value);
    window.localStorage.setItem(
      '@DSL_PAYMENT_PORTAL:companyActive',
      JSON.stringify(value),
    );
  }, []);

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        user,
        companies,
        companyActive,
        loading,
        signIn,
        signOut,
        handleChangeCompanyActive,
      }}>
      {initialized ? <>{children}</> : <OverlayLoading loading />}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider.');
  }

  return context;
}

export {AuthProvider, useAuth};
