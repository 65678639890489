import React, {FC} from 'react';
import {Grid, Card} from '@material-ui/core';

import {useTranslation} from 'react-i18next';

import {useAuth} from 'context/auth';
import {ICompany} from 'types/company';

type Props = {
  company: ICompany | null;
  loading: boolean;
};

export const CompanyInfo: FC<Props> = ({company, loading}: Props) => {
  const {companyActive, user} = useAuth();
  const {t} = useTranslation();

  return (
    <>
      <Grid item xs={12} lg={12}>
        <Card className="card-box mb-0 pt-0">
          <Grid container spacing={0}>
            <Grid item xs={12} lg={6}>
              <div className="d-flex align-items-center pt-3 px-4 mb-0">
                <div className="w-100">
                  <a
                    href="#/"
                    onClick={(e) => e.preventDefault()}
                    className="font-weight-bold font-size-lg"
                    title="...">
                    {companyActive?.nome} - {companyActive?.id}
                  </a>
                  <span className="text-black-50 d-block pb-1">
                    {user?.documento}
                  </span>
                  <div className="d-flex align-items-center pt-2" />
                </div>
              </div>
            </Grid>

            <Grid item xs={12} lg={6}>
              <div className="my-3 font-size-sm p-2 mx-4 bg-secondary rounded-sm">
                <div className="d-flex justify-content-between py-1">
                  <span className="font-weight-bold">
                    {t('company.phone')}:
                  </span>
                  <span className="text-black-50">
                    {loading ? '--' : company?.telefone}
                  </span>
                </div>
                <div className="d-flex justify-content-between py-1">
                  <span className="font-weight-bold">
                    {t('company.locale')}:
                  </span>
                  <span className="text-black-50">
                    {loading
                      ? '--'
                      : `${company?.cidade || ''} - ${
                          company?.estado || ''
                        } - ${company?.pais || ''}`}
                  </span>
                </div>
                <div className="d-flex justify-content-between py-1">
                  <span className="font-weight-bold">
                    {t('company.address')}:
                  </span>
                  <span className="text-black-50">
                    {loading
                      ? '--'
                      : `${company?.endereco || ''} - ${company?.bairro || ''}`}
                  </span>
                </div>
                <div className="d-flex justify-content-between py-1">
                  <span className="font-weight-bold">
                    {t('company.product')}:
                  </span>
                  <span className="text-black-50">
                    {loading ? '--' : company?.produto}
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
        </Card>
      </Grid>

      {/* <Grid item xs={12} md={5}>
        <Card className="card-box bg-primary border-primary p-4 text-white mb-4">
          <h5 className="display-5 font-weight-bold mb-4">Active subscription</h5>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} className="d-flex align-items-center flex-column">
              <div>
                <div className="mb-3">
                  <span className="display-2 font-weight-bold">$00</span>
                  <span className="opacity-8">/ month</span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} className="d-flex align-items-center justify-content-center">
              <div className="text-center">
                <div className="mb-3 font-size-lg font-weight-bold">
                  <b>00</b> days ago
                </div>

                <Button size="small" variant="outlined" className="px-3" color="inherit">
                  Renew now
                </Button>
              </div>
            </Grid>
          </Grid>

          <div className="divider opacity-5 my-4" />

          <div className="text-center">
            <Button size="medium" variant="contained" color="secondary">
              View license details
            </Button>
          </div>
        </Card>
      </Grid> */}
    </>
  );
};
