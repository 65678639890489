/* eslint-disable default-param-last */
import {AnyAction} from 'redux';

// Sidebar
export const SET_SIDEBAR_SHADOW = 'THEME_OPTIONS/SET_SIDEBAR_SHADOW';
export const SET_SIDEBAR_TOGGLE_MOBILE =
  'THEME_OPTIONS/SET_SIDEBAR_TOGGLE_MOBILE';
export const SET_SIDEBAR_FIXED = 'THEME_OPTIONS/SET_SIDEBAR_FIXED';
export const SET_SIDEBAR_FOOTER = 'THEME_OPTIONS/SET_SIDEBAR_FOOTER';
export const SET_SIDEBAR_TOGGLE = 'THEME_OPTIONS/SET_SIDEBAR_TOGGLE';
export const SET_SIDEBAR_USERBOX = 'THEME_OPTIONS/SET_SIDEBAR_USERBOX';
export const SET_SIDEBAR_HOVER = 'THEME_OPTIONS/SET_SIDEBAR_HOVER';

export const setSidebarShadow = (sidebarShadow: boolean) => ({
  type: SET_SIDEBAR_SHADOW,
  sidebarShadow,
});
export const setSidebarFixed = (sidebarFixed: boolean) => ({
  type: SET_SIDEBAR_FIXED,
  sidebarFixed,
});
export const setSidebarToggleMobile = (sidebarToggleMobile: boolean) => ({
  type: SET_SIDEBAR_TOGGLE_MOBILE,
  sidebarToggleMobile,
});
export const setSidebarFooter = (sidebarFooter: boolean) => ({
  type: SET_SIDEBAR_FOOTER,
  sidebarFooter,
});
export const setSidebarToggle = (sidebarToggle: boolean) => ({
  type: SET_SIDEBAR_TOGGLE,
  sidebarToggle,
});
export const setSidebarHover = (sidebarHover: boolean) => ({
  type: SET_SIDEBAR_HOVER,
  sidebarHover,
});
export const setSidebarUserbox = (sidebarUserbox: boolean) => ({
  type: SET_SIDEBAR_USERBOX,
  sidebarUserbox,
});

// Header
export const SET_HEADER_FIXED = 'THEME_OPTIONS/SET_HEADER_FIXED';
export const SET_HEADER_SHADOW = 'THEME_OPTIONS/SET_HEADER_SHADOW';
export const SET_HEADER_SEARCH_HOVER = 'THEME_OPTIONS/SET_HEADER_SEARCH_HOVER';

export const setHeaderFixed = (headerFixed: boolean) => ({
  type: SET_HEADER_FIXED,
  headerFixed,
});
export const setHeaderShadow = (headerShadow: boolean) => ({
  type: SET_HEADER_SHADOW,
  headerShadow,
});
export const setHeaderSearchHover = (headerSearchHover: boolean) => ({
  type: SET_HEADER_SEARCH_HOVER,
  headerSearchHover,
});

// Main content
export const SET_CONTENT_BACKGROUND = 'THEME_OPTIONS/SET_CONTENT_BACKGROUND';
export const SET_THEME_CONFIGURATOR_TOGGLE =
  'THEME_OPTIONS/SET_THEME_CONFIGURATOR_TOGGLE';

export const setContentBackground = (contentBackground: string) => ({
  type: SET_CONTENT_BACKGROUND,
  contentBackground,
});
export const setThemeConfiguratorToggle = (
  themeConfiguratorToggle: boolean,
) => ({
  type: SET_THEME_CONFIGURATOR_TOGGLE,
  themeConfiguratorToggle,
});

// Footer
export const SET_FOOTER_FIXED = 'THEME_OPTIONS/SET_FOOTER_FIXED';
export const SET_FOOTER_SHADOW = 'THEME_OPTIONS/SET_FOOTER_SHADOW';
export const setFooterFixed = (footerFixed: boolean) => ({
  type: SET_FOOTER_FIXED,
  footerFixed,
});
export const setFooterShadow = (footerShadow: boolean) => ({
  type: SET_FOOTER_SHADOW,
  footerShadow,
});

export function reducer(
  state = {
    // Sidebar
    sidebarShadow: false,
    sidebarFixed: true,
    sidebarToggleMobile: false,
    sidebarFooter: true,
    sidebarUserbox: true,
    sidebarToggle: false,
    sidebarHover: false,
    // Header
    headerFixed: true,
    headerShadow: true,
    headerSearchHover: false,
    // Main content
    contentBackground: '',
    themeConfiguratorToggle: false,
    // Footer
    footerFixed: false,
    footerShadow: false,
  },
  action: AnyAction,
) {
  switch (action.type) {
    // Sidebar
    case SET_SIDEBAR_SHADOW:
      return {
        ...state,
        sidebarShadow: action.sidebarShadow,
      };
    case SET_SIDEBAR_FIXED:
      return {
        ...state,
        sidebarFixed: action.sidebarFixed,
      };
    case SET_SIDEBAR_TOGGLE_MOBILE:
      return {
        ...state,
        sidebarToggleMobile: action.sidebarToggleMobile,
      };
    case SET_SIDEBAR_FOOTER:
      return {
        ...state,
        sidebarFooter: action.sidebarFooter,
      };
    case SET_SIDEBAR_TOGGLE:
      return {
        ...state,
        sidebarToggle: action.sidebarToggle,
      };
    case SET_SIDEBAR_HOVER:
      return {
        ...state,
        sidebarHover: action.sidebarHover,
      };
    case SET_SIDEBAR_USERBOX:
      return {
        ...state,
        sidebarUserbox: action.sidebarUserbox,
      };

    // Header
    case SET_HEADER_FIXED:
      return {
        ...state,
        headerFixed: action.headerFixed,
      };
    case SET_HEADER_SHADOW:
      return {
        ...state,
        headerShadow: action.headerShadow,
      };
    case SET_HEADER_SEARCH_HOVER:
      return {
        ...state,
        headerSearchHover: action.headerSearchHover,
      };

    // Main content
    case SET_CONTENT_BACKGROUND:
      return {
        ...state,
        contentBackground: action.contentBackground,
      };
    case SET_THEME_CONFIGURATOR_TOGGLE:
      return {
        ...state,
        themeConfiguratorToggle: action.themeConfiguratorToggle,
      };

    // Footer
    case SET_FOOTER_FIXED:
      return {
        ...state,
        footerFixed: action.footerFixed,
      };
    case SET_FOOTER_SHADOW:
      return {
        ...state,
        footerShadow: action.footerShadow,
      };
    default:
      break;
  }
  return state;
}
