import Swal, {SweetAlertOptions, SweetAlertResult} from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

export const Alert = (
  options: SweetAlertOptions,
  callbackFunction: (result: SweetAlertResult) => void,
) => {
  Swal.fire({
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
    confirmButtonColor: '#5383ff',
    cancelButtonColor: '#d33',
    customClass: {
      container: 'swal-container',
    },
    ...options,
  }).then((result) => {
    if (callbackFunction) {
      callbackFunction(result);
    }
  });
};

export {SweetAlertResult};
