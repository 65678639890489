import React, {FC} from 'react';

import clsx from 'clsx';

import {useAppSelector} from 'hooks/redux';

import {Paper} from '@material-ui/core';

import IconWhats from 'assets/images/icone-whats.png';

const Footer: FC = () => {
  const {footerFixed, footerShadow, sidebarToggle} = useAppSelector(
    (state) => state.theme,
  );

  return (
    <Paper
      square
      elevation={footerShadow ? 11 : 2}
      className={clsx('app-footer text-black-50', {
        'app-footer--fixed': footerFixed,
        'app-footer--fixed__collapsed': sidebarToggle,
      })}>
      <div className="app-footer--inner">
        <div className="app-footer--first">
          <a href="http://wa.me/554933210901" target="_new" rel="nofollow">
            <img src={IconWhats} alt="img-whats" height={45} width="auto" />
          </a>
        </div>
        <div className="app-footer--second">
          <span>Portal do Cliente</span> © 2022 -{' '}
          <a
            href="https://www.desbravador.com.br"
            title="Desbravador Software"
            target="_blank"
            rel="noreferrer">
            Desbravador Software Ltda
          </a>
        </div>
      </div>
    </Paper>
  );
};

export default Footer;
