import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone';

const iconsMap = {
  DashboardTwoToneIcon,
};

export default [
  {
    label: 'Menu',
    content: JSON.parse(
      `[
        {
          "label": "Dashboard",
          "icon": "DashboardTwoToneIcon",
          "content": [
            {
              "label": "Dashboard",
              "description": "This is a dashboard page",
              "to": "/dashboard"
            }
          ]
        }
      ]`,
      (key, value) => {
        if (key === 'icon') {
          return iconsMap[value];
        }
        return value;
      },
    ),
  },
];
