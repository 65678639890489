import React, {FC, useCallback, useMemo, useRef, useState} from 'react';

import {Grid, Card, Button, Tooltip} from '@material-ui/core';
import {FaFilePdf, FaFileInvoice} from 'react-icons/fa';
import {SyncLoader} from 'react-spinners';
// import Frame from 'react-frame-component';

import PixIcon from 'assets/images/pix.png';
import PixGrayIcon from 'assets/images/pix_gray.png';

import {useTranslation} from 'react-i18next';

import {IInstallment, ILinkBank} from 'types';
// import {IFrameFormItau} from './IFrameFormItau';
// import {ModalPdf} from './ModalPdf';
import {Alert} from '@material-ui/lab';
import {ModalPix} from './ModalPix';

type Props = {
  loading?: boolean;
  linkBank: ILinkBank | null;
  installments: IInstallment[] | null;
  handleRefresh: () => void;
};

export const CompanyHistoryTable: FC<Props> = ({
  loading,
  linkBank,
  installments,
  handleRefresh,
}: Props) => {
  const [installmentActive, setInstallmentActive] =
    useState<IInstallment | null>(null);
  const [modalPixVisible, setModalPixVisible] = useState<boolean>(false);
  const formRef =
    useRef<HTMLFormElement>() as React.MutableRefObject<HTMLFormElement>;
  const isItau = useMemo(() => {
    return !!linkBank?.linkitau;
  }, [linkBank]);

  const showNF = useMemo(() => {
    return installments && installments.length > 0 && installments[0].nota;
  }, [installments]);
  const showPix = useMemo(() => {
    return installments && installments.length > 0 && installments[0].aceitapix;
  }, [installments]);

  const {t} = useTranslation();

  const handleOpenPdf = (link: string, type: string) => {
    if (isItau && type === 'BOLETO') {
      formRef.current?.requestSubmit();
    } else if (link) {
      window.open(link, '_blank');
    }
  };

  const renderBillingList = () => {
    return (
      <>
        {isItau && linkBank?.chaveitau ? (
          <form
            ref={formRef}
            action="https://ww2.itau.com.br/2viabloq/pesquisa.asp"
            method="post"
            target="_blank"
            name="frm"
            id="frm-itau">
            <input type="hidden" name="DC" value={linkBank?.chaveitau || ''} />
            <input type="hidden" name="msg" value="S" />
          </form>
        ) : null}
        <table className="table table-borderless text-nowrap mb-0">
          <thead>
            <tr>
              <th className="text-uppercase bg-secondary">
                {t('finance.type')}
              </th>
              <th className="text-uppercase bg-secondary">
                {t('finance.dueDate')}
              </th>
              <th className="text-uppercase bg-secondary">
                {t('finance.situation')}
              </th>
              <th className="text-uppercase bg-secondary">
                {t('finance.value')}
              </th>
              <th className="text-uppercase bg-secondary">
                {t('finance.daysLate')}
              </th>
              <th className="text-uppercase bg-secondary">
                {t('finance.finalValue')}
              </th>
              <th className="text-uppercase bg-secondary text-center">
                {t('finance.payment')}
              </th>

              <th className="text-uppercase bg-secondary text-center">
                {showNF ? t('finance.note') : ''}
              </th>
            </tr>
          </thead>
          <tbody>
            {!loading &&
              installments?.map((installment) => {
                const PAYMENT_DONE = installment.situacao === 'Pago';
                const PAYMENT_PENDING = installment.situacao === 'Pendente';
                return (
                  <tr key={installment.chave}>
                    <td>{installment.tipofatura}</td>
                    <td>{installment.vencimento}</td>
                    <td>
                      {PAYMENT_PENDING ? (
                        <div className="badge badge-warning px-4">
                          {installment.situacao}
                        </div>
                      ) : null}
                      {PAYMENT_DONE ? (
                        <div className="badge badge-success px-4">
                          {installment.situacao}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      {installment.moeda} {installment.valor}
                    </td>
                    <td>{installment.atraso || ''}</td>
                    <td>
                      {installment.moeda}{' '}
                      {PAYMENT_DONE
                        ? installment.valorpagamento
                        : installment.valortotal}
                    </td>
                    <td className="text-center">
                      <Tooltip arrow title={`${t('finance.ticket')}`}>
                        <Button
                          size="large"
                          color="primary"
                          className="py-1 px-2"
                          onClick={() => {
                            handleOpenPdf(installment.boleto, 'BOLETO');
                          }}>
                          <FaFilePdf size={30} />
                        </Button>
                      </Tooltip>

                      {!!installment.aceitapix && showPix && PAYMENT_DONE ? (
                        <Button
                          disabled
                          size="large"
                          color="primary"
                          className="py-1 px-2">
                          <img
                            src={PixGrayIcon}
                            alt="pix icon"
                            style={{width: '30px'}}
                          />
                        </Button>
                      ) : null}

                      {!!installment.aceitapix && showPix && !PAYMENT_DONE ? (
                        <Tooltip arrow title={`${t('finance.pix')}`}>
                          <Button
                            size="large"
                            color="primary"
                            className="py-1 px-2"
                            onClick={() => {
                              setInstallmentActive(installment);
                              setModalPixVisible(true);
                            }}>
                            <img
                              src={PixIcon}
                              alt="pix icon"
                              style={{width: '30px'}}
                            />
                          </Button>
                        </Tooltip>
                      ) : null}
                    </td>

                    <td className="text-center">
                      {showNF ? (
                        <Tooltip arrow title={installment.referencia}>
                          <Button
                            size="large"
                            color="primary"
                            className="py-1 px-2"
                            onClick={() => {
                              handleOpenPdf(installment.nota, 'NOTA');
                            }}>
                            <FaFileInvoice size={30} />
                          </Button>
                        </Tooltip>
                      ) : null}
                    </td>
                  </tr>
                );
              })}

            {!loading && (!installments || installments?.length === 0) ? (
              <tr>
                <td colSpan={9}>
                  <div className="text-center text-black-50">
                    <small>{t('error.empty')}</small>
                  </div>
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </>
    );
  };

  const handleConfirmPix = useCallback(() => {
    setModalPixVisible(false);
    setInstallmentActive(null);

    handleRefresh();
  }, [handleRefresh]);

  const handleClosePix = useCallback(() => {
    setModalPixVisible(false);
    setInstallmentActive(null);

    handleRefresh();
  }, [handleRefresh]);

  return (
    <>
      <Grid item xs={12} lg={12}>
        {showPix ? <Alert severity="info">{t('finance.pixInfo')}</Alert> : null}
      </Grid>
      <Grid item xs={12} lg={12}>
        <Card className="card-box mb-4">
          <div className="card-header">
            <div className="card-header--title">
              <h4 className="font-size-lg mb-0 py-2 font-weight-bold">
                {t('finance.history')}
              </h4>
            </div>
            <div className="card-header--actions" />
          </div>

          {/* <ModalPdf
            url={pdfActive || null}
            visible={!!pdfActive}
            handleClose={() => {
              setPdfActive(null);
            }}
          /> */}

          <ModalPix
            visible={modalPixVisible}
            installment={installmentActive}
            handleClose={handleClosePix}
            handleConfirm={handleConfirmPix}
          />

          <div className="card-body px-0 pt-0 pb-0">
            <div className="table-responsive">
              {!loading ? (
                <>{renderBillingList()}</>
              ) : (
                <div className="d-flex align-items-center flex-column px-4 py-4">
                  <SyncLoader color="#5383ff" loading />
                </div>
              )}
            </div>
          </div>
        </Card>
      </Grid>
    </>
  );
};
