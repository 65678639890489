import * as Yup from 'yup';

import {TFunction} from 'i18next';

export const SignupSchema1 = (t: TFunction) => {
  return Yup.object().shape({
    cnpj: Yup.string()
      .required(t('validation.required'))
      .required(t('validation.required'))
      .min(
        14,
        t('validation.min', {
          min: 14,
        }),
      ),
  });
};

export const SignupSchema2 = (t: TFunction) => {
  return Yup.object().shape({
    cnpjConfirm: Yup.string()
      .required(t('validation.required'))
      .min(
        14,
        t('validation.min', {
          min: 14,
        }),
      ),
    tmpPassword: Yup.string()
      .required(t('validation.required'))
      .min(
        6,
        t('validation.min', {
          min: 6,
        }),
      ),
    newPassword: Yup.string()
      .required(t('validation.required'))
      .min(
        6,
        t('validation.min', {
          min: 6,
        }),
      ),
    newPasswordConfirm: Yup.string()
      .required(t('validation.required'))
      .oneOf([Yup.ref('newPassword'), t('validation.notMatch')]),
  });
};
