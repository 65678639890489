import axios, {AxiosError, AxiosResponse, AxiosInstance} from 'axios';

const {CancelToken, isCancel, isAxiosError} = axios;

const api: AxiosInstance = axios.create({
  baseURL:
    'https://servicos.desbravador.com.br/portal/data/administrativo/view/modules/payments/api',
  // baseURL: 'https://268f-189-39-32-185.ngrok.io',
});

export default api;

export {CancelToken, isCancel, isAxiosError, AxiosError};
export type {AxiosResponse};
