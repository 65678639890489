import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import PerfectScrollbar from 'react-perfect-scrollbar';
import {Hidden, Drawer, Paper} from '@material-ui/core';

import {connect} from 'react-redux';

import {
  setSidebarToggleMobile,
  setSidebarHover,
  setSidebarToggle,
  setSidebarFooter,
  setSidebarUserbox,
} from 'store/ducks/theme';
import SidebarHeader from './SidebarHeader';
import SidebarUserBox from './SidebarUserBox';
import SidebarMenu from './SidebarMenu';
import SidebarFooter from './SidebarFooter';

import navItems from './navItems';

const Sidebar = ({
  setSidebarToggleMobileDispatch,
  sidebarToggleMobile,
  sidebarFixed,
  sidebarHover,
  setSidebarHoverDispatch,
  sidebarToggle,
  sidebarUserbox,
  sidebarShadow,
}) => {
  const toggleHoverOn = () => setSidebarHoverDispatch(true);
  const toggleHoverOff = () => setSidebarHoverDispatch(false);

  const closeDrawer = () =>
    setSidebarToggleMobileDispatch(!sidebarToggleMobile);

  const sidebarMenuContent = (
    <div
      className={clsx({
        'app-sidebar-nav-close': sidebarToggle && !sidebarHover,
      })}>
      {navItems.map((list) => (
        <SidebarMenu
          component="div"
          key={list.label}
          pages={list.content}
          title={list.label}
        />
      ))}
    </div>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={sidebarToggleMobile}
          onClose={closeDrawer}
          variant="temporary"
          elevation={4}
          className="app-sidebar-wrapper-lg">
          <SidebarHeader />
          <PerfectScrollbar>
            {sidebarUserbox && <SidebarUserBox />}
            {sidebarMenuContent}
          </PerfectScrollbar>
        </Drawer>
      </Hidden>

      <Hidden mdDown>
        <Paper
          onMouseEnter={toggleHoverOn}
          onMouseLeave={toggleHoverOff}
          className={clsx('app-sidebar-wrapper', {
            'app-sidebar-wrapper-close': sidebarToggle,
            'app-sidebar-wrapper-open': sidebarHover,
            'app-sidebar-wrapper-fixed': sidebarFixed,
          })}
          square
          open={sidebarToggle}
          elevation={sidebarShadow ? 11 : 3}>
          <SidebarHeader />
          <div
            className={clsx({
              'app-sidebar-menu': sidebarFixed,
              'app-sidebar-collapsed': sidebarToggle && !sidebarHover,
            })}>
            <PerfectScrollbar options={{wheelPropagation: false}}>
              {/* {sidebarUserbox && <SidebarUserBox />} */}
              <div className="">{sidebarMenuContent}</div>

              <SidebarFooter />
            </PerfectScrollbar>
          </div>
        </Paper>
      </Hidden>
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarFixed: state.theme.sidebarFixed,
  headerFixed: state.theme.headerFixed,
  sidebarToggle: state.theme.sidebarToggle,
  sidebarHover: state.theme.sidebarHover,
  sidebarShadow: state.theme.sidebarShadow,
  sidebarUserbox: state.theme.sidebarUserbox,
  sidebarToggleMobile: state.theme.sidebarToggleMobile,
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobileDispatch: (enable) =>
    dispatch(setSidebarToggleMobile(enable)),
  setSidebarToggleDispatch: (enable) => dispatch(setSidebarToggle(enable)),
  setSidebarHoverDispatch: (enable) => dispatch(setSidebarHover(enable)),
  setSidebarFooterDispatch: (enable) => dispatch(setSidebarFooter(enable)),
  setSidebarUserboxDispatch: (enable) => dispatch(setSidebarUserbox(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);

Sidebar.propTypes = {
  setSidebarToggleMobileDispatch: PropTypes.func.isRequired,
  sidebarToggleMobile: PropTypes.bool.isRequired,
  sidebarFixed: PropTypes.bool.isRequired,
  sidebarHover: PropTypes.bool.isRequired,
  setSidebarHoverDispatch: PropTypes.func.isRequired,
  sidebarToggle: PropTypes.bool.isRequired,
  sidebarUserbox: PropTypes.bool.isRequired,
  sidebarShadow: PropTypes.bool.isRequired,
};
