import React from 'react';
import {Switch, Route, Redirect, useLocation} from 'react-router-dom';

import {AuthProvider} from 'context/auth';

import {ThemeProvider} from '@material-ui/styles';

import {Auth} from 'pages/auth';
import {PasswordReset} from 'pages/passwordReset';
import {Dashboard} from 'pages/dashboard';
import {NotFound} from 'pages/notFound';

import MuiTheme from 'theme';

import {LeftSidebar} from './layout/LeftSidebar';
import {MinimalLayout} from './layout/MinimalLayout';

import {Interceptor} from './Interceptor';
import {Tracker} from './AnalyticsTrackers';

const AppNavigation = () => {
  const location = useLocation();

  return (
    <>
      <ThemeProvider theme={MuiTheme}>
        <AuthProvider>
          <>
            <Switch>
              <Redirect exact from="/" to="/dashboard" />

              <Route path={['/auth', '/password-reset']}>
                <MinimalLayout>
                  <Switch location={location} key={location.pathname}>
                    <Route path="/auth" component={Auth} />
                    <Route path="/password-reset" component={PasswordReset} />
                  </Switch>
                </MinimalLayout>
              </Route>

              <Route path={['/dashboard']}>
                <Interceptor />
                <Tracker />
                <LeftSidebar>
                  <Switch location={location} key={location.pathname}>
                    <Route path="/dashboard" component={Dashboard} />
                  </Switch>
                </LeftSidebar>
              </Route>

              <Route path="*" component={NotFound} />
            </Switch>
          </>
        </AuthProvider>
      </ThemeProvider>
    </>
  );
};

export default AppNavigation;
