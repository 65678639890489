import React from 'react';

import {useAuth} from 'context/auth';

import {FaAngleDown} from 'react-icons/fa';

import {
  Avatar,
  Box,
  Badge,
  Menu,
  Button,
  List,
  ListItem,
  Divider,
} from '@material-ui/core';

import {withStyles} from '@material-ui/core/styles';
import avatarDsl from 'assets/images/profile.png';
import {useTranslation} from 'react-i18next';

const StyledBadge = withStyles({
  badge: {
    backgroundColor: 'var(--success)',
    color: 'var(--success)',
    boxShadow: '0 0 0 2px #fff',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
})(Badge);

export default function HeaderUserbox() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {user, signOut} = useAuth();
  const {t} = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        color="inherit"
        onClick={handleClick}
        className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center">
        <Box style={{border: '2px solid #FFF', borderRadius: '50px'}}>
          <StyledBadge
            overlap="circular"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            variant="dot">
            <Avatar sizes="44" alt="avatar" src={avatarDsl} />
          </StyledBadge>
        </Box>
        <div className="d-none d-xl-block pl-3">
          <div className="font-weight-bold pt-2 line-height-1">
            {user?.fantasia}
          </div>
          <span className="text-white-50">{user?.documento}</span>
        </div>
        <span className="pl-1 pl-xl-3">
          <FaAngleDown className="opacity-5" />
        </span>
      </Button>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        onClose={handleClose}
        className="ml-2">
        <div className="dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
          <List className="text-left bg-transparent d-flex align-items-center flex-column pt-0">
            <Box>
              <StyledBadge
                overlap="circular"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                variant="dot">
                <Avatar sizes="44" alt="avatar" src={avatarDsl} />
              </StyledBadge>
            </Box>
            <div className="pl-3 ">
              <div className="font-weight-bold text-center pt-2 line-height-1">
                {user?.fantasia}
              </div>
              <span className="text-black-50 text-center">
                {user?.documento}
              </span>
            </div>
            <Divider className="w-100 mt-2" />
            <ListItem
              button
              onClick={() => {
                signOut();
              }}>
              {t('auth.logout')}
            </ListItem>
            <Divider className="w-100" />
          </List>
        </div>
      </Menu>
    </>
  );
}
