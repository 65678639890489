import React, {FC} from 'react';
import clsx from 'clsx';

import {useAppSelector} from 'hooks/redux';
import {useAuth} from 'context/auth';

import {Redirect} from 'react-router-dom';

import Header from './header';
import Footer from './footer';
import Sidebar from './sidebar';

export const LeftSidebar: FC = ({children}) => {
  const {footerFixed, sidebarFixed, sidebarToggle, contentBackground} =
    useAppSelector((state) => state.theme);

  const {user} = useAuth();

  if (!user) {
    return <Redirect to="/auth" />;
  }

  return (
    <div className={clsx('app-wrapper', contentBackground)}>
      <Header />
      <div
        className={clsx('app-main', {
          'app-main-sidebar-static': !sidebarFixed,
        })}>
        <Sidebar />
        <div
          className={clsx('app-content', {
            'app-content-sidebar-collapsed': sidebarToggle,
            'app-content-sidebar-fixed': sidebarFixed,
            'app-content-footer-fixed': footerFixed,
          })}>
          <div className="app-content--inner">
            <div className="app-content--inner__wrapper">{children}</div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};
