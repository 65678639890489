import React from 'react';

import {Box} from '@material-ui/core';

import LogoDsl from 'assets/images/logo-dsl.png';

export default function SidebarFooter() {
  return (
    <>
      <Box className="app-sidebar-footer-wrapper">
        <div className="app-sidebar-footer" style={{height: '180px'}}>
          <img src={LogoDsl} alt="logo dsl" style={{maxWidth: '140px'}} />
        </div>
      </Box>
    </>
  );
}
