import React, {FC, useEffect, useState, useCallback} from 'react';

import {Grid, Box, Paper} from '@material-ui/core';

import {AxiosError, AxiosResponse} from 'axios';
import api from 'services/apiDslService';

import {useAuth} from 'context/auth';

import {ICompany, ILinkBank, IInstallment} from 'types';

import {CompanySelect} from './components/companySelect';
import {CompanyInfo} from './components/companyInfo';
import {CompanyHistoryTable} from './components/companyHistoryTable';

interface IState {
  loading: boolean;
  error: string | boolean | null;
  company: ICompany | null;
  linkBank: ILinkBank | null;
  installments: IInstallment[] | null;
}

export const Dashboard: FC = () => {
  const {companyActive} = useAuth();
  const [state, setState] = useState<IState>({
    loading: true,
    error: null,
    company: null,
    linkBank: null,
    installments: null,
  });

  const loadData = useCallback((companyId) => {
    setState({
      loading: true,
      error: null,
      company: null,
      linkBank: null,
      installments: null,
    });

    const request1 = api.get(`/company/?id=${companyId}`);
    const request2 = api.get(`/getfinanceiro/?id=${companyId}`);

    Promise.all([request1, request2])
      .then((response: AxiosResponse[]) => {
        setState({
          loading: false,
          error: null,
          company: response[0].data?.cliente?.dados,
          linkBank: Array.isArray(response[1].data?.linkbanco)
            ? response[1].data?.linkbanco[0]
            : null,
          installments: response[1].data?.parcelas,
        });
      })
      .catch((error: AxiosError) => {
        setState({
          loading: false,
          error: error.message,
          company: null,
          linkBank: null,
          installments: null,
        });
      });
  }, []);

  useEffect(() => {
    if (companyActive) {
      loadData(companyActive?.id);
    }
  }, [companyActive, loadData]);

  const handleRefresh = useCallback(() => {
    loadData(companyActive?.id);
  }, [companyActive, loadData]);

  return (
    <>
      <Paper
        square
        elevation={2}
        className="app-page-title"
        style={{padding: '1rem'}}>
        <div>
          <Box className="app-page-title--first">
            <div className="app-page-title--heading">
              <CompanySelect loading={state.loading} />
            </div>
          </Box>
        </div>
      </Paper>

      <Grid container spacing={4}>
        <CompanyInfo company={state.company} loading={state.loading} />

        <CompanyHistoryTable
          loading={state.loading}
          installments={state.installments}
          linkBank={state.linkBank}
          handleRefresh={handleRefresh}
        />
      </Grid>
    </>
  );
};
