import * as Yup from 'yup';

import {TFunction} from 'i18next';

export const SignupSchema = (t: TFunction) => {
  return Yup.object().shape({
    cnpj: Yup.string()
      .required(t('validation.required'))
      .min(
        14,
        t('validation.min', {
          min: 14,
        }),
      ),
    password: Yup.string().required(t('validation.required')),
  });
};
