export const maskCpfCnpj = (v: string) => {
  const unMaskedValue = v.replace(/\D/g, '');
  let maskedValue = unMaskedValue;

  if (unMaskedValue.length <= 11) {
    maskedValue = maskedValue.replace(/(\d{3})(\d)/, '$1.$2');
    maskedValue = maskedValue.replace(/(\d{3})(\d)/, '$1.$2');
    maskedValue = maskedValue.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  } else {
    maskedValue = maskedValue.replace(/^(\d{2})(\d)/, '$1.$2');
    maskedValue = maskedValue.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
    maskedValue = maskedValue.replace(/\.(\d{3})(\d)/, '.$1/$2');
    maskedValue = maskedValue.replace(/(\d{4})(\d)/, '$1-$2');
  }

  return maskedValue;
};
