import React, {Fragment} from 'react';

import PropTypes from 'prop-types';

export const MinimalLayout = ({children}) => {
  return <>{children}</>;
};

MinimalLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
