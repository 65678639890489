import i18next, {LanguageDetectorModule} from 'i18next';
import {initReactI18next} from 'react-i18next';

import pt from './pt/pt';
import en from './en/en';
import es from './es/es';

const resources = {
  pt,
  en,
  es,
};

const languageDetector: LanguageDetectorModule = {
  type: 'languageDetector',
  detect: () => {
    if (navigator) {
      const lng = navigator.language;
      let normalizedLang = lng ? String(lng).slice(0, 2) : 'pt';
      if (
        normalizedLang !== 'pt' &&
        normalizedLang !== 'es' &&
        normalizedLang !== 'en'
      ) {
        normalizedLang = 'pt';
      }
      return normalizedLang;
    }
    return 'pt';
  },
  init: () => {
    // NOTHING
  },
  cacheUserLanguage: () => {
    // NOTHING
  },
};

i18next
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'pt',
    debug: process.env.NODE_ENV === 'development',
    resources,
    ns: ['translation'],
    defaultNS: 'translation',
    nsSeparator: false,
    returnNull: false,
  });

export default i18next;
