import React, {useEffect} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {SnackbarProvider} from 'notistack';

import './assets/base.scss';
import CssBaseline from '@material-ui/core/CssBaseline';

import './locale/i18n';

import AppNavigation from './routes';

import store from './store';

function App() {
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(process.env.NODE_ENV);
  }, []);

  return (
    <Provider store={store}>
      <SnackbarProvider
        autoHideDuration={3000}
        maxSnack={3}
        preventDuplicate
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}>
        <BrowserRouter>
          <CssBaseline />
          <AppNavigation />
        </BrowserRouter>
      </SnackbarProvider>
    </Provider>
  );
}

export default App;
