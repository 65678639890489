const PIX_DEV = {
  env: 'development',
  access_key: 'gdvufDuyybqXGONwYUlVQA',
  secret_key:
    'jytaNw69PdpNZlWwsRfy0YvMrdUHJj64uHwvRqi-u-HQ1hULOGX_TjARxnI4P23VF7cCHwWsnYxNO0MXIuvrlw',
  client_id:
    'sxkdR6H_OmAzRWli63RG4zEbWl4LL1wabgJ4ZHxPHoNr-7lGUEi_QHbFUhHo-gxrk8caA9Y-ZKLjgWcyXfGyBT5mAHL3daIVRGv1mpV2CWw7dzxDv7GzL4zqcfGPiyb4oMB18aDjqCi6IP3AsaecOUlw7zthtnzhsMZ_APqE4u0',
};

const PIX_PROD = {
  env: 'production',
  access_key: '6eFWLXgxLa-DYL5I5CfkfA',
  secret_key:
    'l_MUEbTXvVJfSjjvW4J3Uh_OMgBHqcA41RAgVHb9RCfvwsTvd_H_WVAF2UGzJB7talAIWIrLU7yAyBA1PycPnA',
  client_id:
    'Mtg0gSkJKMhX--QwPEaBJlwxefF-zNqoGWfVmsaZZDqzUYhpVMqZBx_Fl4v5QVrE6iiO6FKh1oLH0Iw2Nxxi_NobLDmpNcjAFQhyOn0VRrzqmvIQ0U7rdhDZTdKEISl8LuJMPwxAoQVUPh4WRuGN6nCWD-LxgEvmpxXtZE1wcBA',
};

const isDev = process.env.NODE_ENV === 'development';

const PIX_CONFIG = isDev ? PIX_DEV : PIX_PROD;

export default PIX_CONFIG;
