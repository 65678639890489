import React, {FC} from 'react';

import {Button, ButtonGroup, Menu, MenuItem} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import {useAuth} from 'context/auth';

interface Company {
  id: number;
  nome: string;
  documento: string;
  fantasia: string;
}

type Props = {
  loading: boolean;
};

export const CompanySelect: FC<Props> = ({loading}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const {companies, companyActive, handleChangeCompanyActive} = useAuth();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const button: HTMLButtonElement = event.currentTarget;

    setAnchorEl(button);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (newCompany: Company) => {
    handleClose();
    handleChangeCompanyActive(newCompany);
  };

  if (!companies || companies.length < 2) {
    return null;
  }

  return (
    <>
      <div className="app-page-title--description" style={{margin: 0}}>
        <>
          <ButtonGroup
            variant="contained"
            color="primary"
            aria-label="company button">
            <Button style={{minWidth: '300px', justifyContent: 'flex-start'}}>
              {companyActive?.fantasia}
            </Button>
            <Button
              color="primary"
              size="small"
              aria-haspopup="true"
              onClick={handleClick}
              disabled={loading}>
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Menu
            id="simple-menu2"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}>
            {companies &&
              companies.map((company) => (
                <MenuItem
                  onClick={() => {
                    handleChange(company);
                  }}
                  key={company.id}>
                  {company.nome}
                </MenuItem>
              ))}
          </Menu>
        </>
      </div>
    </>
  );
};
