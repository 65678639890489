import axios, {AxiosError, AxiosResponse, AxiosInstance} from 'axios';
import {encode} from 'base-64';

const {CancelToken, isCancel, isAxiosError} = axios;

let apiPayUrl = 'https://pay.desbravadorweb.com.br/api';

if (process.env.NODE_ENV === 'development') {
  // apiPayUrl = 'http://localhost:3333/api';
  apiPayUrl = 'https://hml.pay.hmldesbravador.com/api';
}

const api: AxiosInstance = axios.create({
  baseURL: apiPayUrl,
  headers: {
    Authorization: `Basic ${encode('dsl:TeMzkc3f5eY2hXZ5rg2YR8EM')}`,
  },
});

export default api;

export {CancelToken, isCancel, isAxiosError, AxiosError};
export type {AxiosResponse};
