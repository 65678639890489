import React, {FC, useCallback, useRef} from 'react';

import {
  Grid,
  Container,
  Input,
  InputLabel,
  InputAdornment,
  FormHelperText,
  Card,
  CardContent,
  Button,
  FormControl,
  LinearProgress,
} from '@material-ui/core';

import {useTranslation} from 'react-i18next';
import {useHistory, NavLink as RouterLink} from 'react-router-dom';
import {useAuth} from 'context/auth';

import {
  Formik,
  Form,
  Field,
  FormikProps,
  FormikHelpers,
  FieldProps,
} from 'formik';

import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import LogoDsl from 'assets/images/logo-dsl.png';

import {SignupSchema} from './formValidation';
import {maskCpfCnpj} from './utils';

interface FormValues {
  cnpj: string;
  password: string;
}

export const Auth: FC = () => {
  const formikRef = useRef<FormikProps<FormValues>>(null);
  const history = useHistory();
  const {t} = useTranslation();
  const {signIn, loading} = useAuth();

  const handleSubmitForm = useCallback(
    (values: FormValues) => {
      signIn(values)
        .then(() => {
          formikRef.current?.setSubmitting(false);

          history.replace('/dashboard');
        })
        .catch(() => {
          formikRef.current?.resetForm();
        });
    },
    [history, signIn],
  );

  return (
    <div className="app-wrapper min-vh-100">
      <div className="app-main flex-column">
        <div className="app-content p-0">
          <div className="app-content--inner d-flex align-items-center">
            <div className="flex-grow-1 w-100 d-flex align-items-center">
              <div className="bg-composed-wrapper--content py-5">
                <Container maxWidth="lg">
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      className="d-flex align-items-center">
                      <img
                        alt="logo dsl"
                        className="w-50 mx-auto d-block img-fluid"
                        src={LogoDsl}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={8}
                      className="d-flex flex-column align-items-center">
                      <Formik
                        innerRef={formikRef}
                        enableReinitialize={false}
                        initialValues={{
                          cnpj: '',
                          password: '',
                        }}
                        validationSchema={SignupSchema(t)}
                        validateOnMount={false}
                        onSubmit={(
                          values: FormValues,
                          {setSubmitting}: FormikHelpers<FormValues>,
                        ) => {
                          setSubmitting(true);

                          handleSubmitForm(values);
                        }}>
                        {({
                          isSubmitting,
                          isValidating,
                          touched,
                          handleChange,
                          setFieldValue,
                          handleBlur,
                          values,
                          errors,
                        }) => {
                          return (
                            <Card className="m-0 w-100 p-0 border-0">
                              <div className="card-header d-block p-3 mx-2 mb-0 mt-2 rounded border-0" />
                              <CardContent className="p-3">
                                <div className="text-center text-black-50 mb-3">
                                  <span>{t('auth.title')}</span>
                                </div>

                                <Form
                                  noValidate
                                  autoComplete="off"
                                  className="px-5">
                                  <div className="mb-3">
                                    <FormControl className="w-100">
                                      <InputLabel htmlFor="auth-cnpj">
                                        {t('auth.cpfCnpj')}
                                      </InputLabel>
                                      <Field name="cnpj">
                                        {({field}: FieldProps) => (
                                          <>
                                            <Input
                                              autoComplete="off"
                                              value={values.cnpj || ''}
                                              error={
                                                !!errors.cnpj && !!touched.cnpj
                                              }
                                              onChange={(e) => {
                                                setFieldValue(
                                                  'cnpj',
                                                  maskCpfCnpj(e.target.value),
                                                );
                                              }}
                                              onBlur={handleBlur}
                                              name={field.name}
                                              fullWidth
                                              id="auth-cnpj"
                                              startAdornment={
                                                <InputAdornment position="start">
                                                  <PersonOutlineOutlinedIcon />
                                                </InputAdornment>
                                              }
                                              inputProps={{
                                                maxLength: 18,
                                              }}
                                            />
                                            {!!errors.cnpj && !!touched.cnpj ? (
                                              <FormHelperText error>
                                                {errors.cnpj}
                                              </FormHelperText>
                                            ) : null}
                                          </>
                                        )}
                                      </Field>
                                    </FormControl>
                                  </div>
                                  <div className="mb-3">
                                    <FormControl className="w-100">
                                      <InputLabel htmlFor="password">
                                        {t('auth.password')}
                                      </InputLabel>
                                      <Field name="password">
                                        {({field}: FieldProps) => (
                                          <>
                                            <Input
                                              autoComplete="off"
                                              value={values.password || ''}
                                              error={
                                                !!errors.password &&
                                                !!touched.password
                                              }
                                              onChange={(e) => {
                                                handleChange(e);
                                              }}
                                              type="password"
                                              onBlur={handleBlur}
                                              name={field.name}
                                              fullWidth
                                              id="auth-password"
                                              startAdornment={
                                                <InputAdornment position="start">
                                                  <LockOutlinedIcon />
                                                </InputAdornment>
                                              }
                                            />
                                            {!!errors.password &&
                                            !!touched.password ? (
                                              <FormHelperText error>
                                                {errors.password}
                                              </FormHelperText>
                                            ) : null}
                                          </>
                                        )}
                                      </Field>
                                    </FormControl>
                                  </div>

                                  <div className="text-center">
                                    <Button
                                      disabled={
                                        isSubmitting || isValidating || loading
                                      }
                                      type="submit"
                                      color="primary"
                                      variant="contained"
                                      size="large"
                                      className="my-2"
                                      style={{minWidth: '120px'}}>
                                      {t('auth.login')}
                                    </Button>
                                  </div>

                                  <div
                                    className="mb-3 py-2"
                                    style={{minHeight: '20px'}}>
                                    {loading ? (
                                      <LinearProgress
                                        value={100}
                                        variant="indeterminate"
                                      />
                                    ) : null}
                                  </div>

                                  <div className="divider opacity-5 my-2" />

                                  <div className="text-center">
                                    <Button
                                      disabled={
                                        isSubmitting || isValidating || loading
                                      }
                                      type="button"
                                      color="primary"
                                      className="m-2 text-first"
                                      size="large"
                                      component={RouterLink}
                                      to="/password-reset">
                                      {t('auth.forgotPassword')}
                                    </Button>
                                  </div>
                                </Form>
                              </CardContent>
                            </Card>
                          );
                        }}
                      </Formik>
                    </Grid>
                  </Grid>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
