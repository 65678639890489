import React, {ForwardedRef, forwardRef} from 'react';
import {
  Input,
  InputLabel,
  InputAdornment,
  FormHelperText,
  Card,
  CardContent,
  Button,
  FormControl,
  LinearProgress,
} from '@material-ui/core';

import {
  Formik,
  Form,
  Field,
  FormikProps,
  FormikHelpers,
  FieldProps,
} from 'formik';

import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import {useTranslation} from 'react-i18next';

import {SignupSchema2} from './formValidation';
import {maskCpfCnpj} from '../auth/utils';

interface FormValuesReset {
  cnpjConfirm: string;
  tmpPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
}

type Props = {
  loading: boolean;
  handleSubmitForm: (values: FormValuesReset) => void;
  cnpjActive: string;
};

export const Form2 = forwardRef(
  (
    {loading, handleSubmitForm, cnpjActive}: Props,
    ref: ForwardedRef<FormikProps<FormValuesReset>>,
  ) => {
    const {t} = useTranslation();
    return (
      <Formik
        innerRef={ref}
        enableReinitialize={false}
        initialValues={{
          cnpjConfirm: cnpjActive,
          tmpPassword: '',
          newPassword: '',
          newPasswordConfirm: '',
        }}
        validationSchema={SignupSchema2(t)}
        validateOnMount={false}
        onSubmit={(
          values: FormValuesReset,
          {setSubmitting}: FormikHelpers<FormValuesReset>,
        ) => {
          setSubmitting(true);

          handleSubmitForm(values);
        }}>
        {({
          isSubmitting,
          isValidating,
          touched,
          setFieldValue,
          handleBlur,
          values,
          errors,
        }) => {
          return (
            <Card className="m-0 w-100 p-0 border-0">
              <div className="card-header d-block p-3 mx-2 mb-0 mt-2 rounded border-0" />
              <CardContent className="p-3">
                <div className="text-center text-black-50 mb-3">
                  <span>{t('auth.forgot')}</span>
                </div>

                <Form noValidate autoComplete="off" className="px-5">
                  <div className="mb-3">
                    <FormControl className="w-100">
                      <InputLabel htmlFor="auth-reset-cnpj2">
                        {t('auth.cpfCnpj')}
                      </InputLabel>
                      <Field name="cnpj">
                        {({field}: FieldProps) => (
                          <>
                            <Input
                              autoComplete="off"
                              value={values.cnpjConfirm || ''}
                              error={
                                !!errors.cnpjConfirm && !!touched.cnpjConfirm
                              }
                              onChange={(e) => {
                                setFieldValue(
                                  'cnpj',
                                  maskCpfCnpj(e.target.value),
                                );
                              }}
                              onBlur={handleBlur}
                              name={field.name}
                              fullWidth
                              id="auth-reset-cnpj2"
                              startAdornment={
                                <InputAdornment position="start">
                                  <PersonOutlineOutlinedIcon />
                                </InputAdornment>
                              }
                              inputProps={{
                                maxLength: 18,
                              }}
                            />
                            {!!errors.cnpjConfirm && !!touched.cnpjConfirm ? (
                              <FormHelperText error>
                                {errors.cnpjConfirm}
                              </FormHelperText>
                            ) : null}
                          </>
                        )}
                      </Field>
                    </FormControl>
                  </div>

                  <div className="mb-3">
                    <FormControl className="w-100">
                      <InputLabel htmlFor="auth-reset-tmp-password">
                        {t('auth.tmpPassword')}
                      </InputLabel>
                      <Field name="tmpPassword">
                        {({field}: FieldProps) => (
                          <>
                            <Input
                              autoComplete="off"
                              value={values.tmpPassword || ''}
                              error={
                                !!errors.tmpPassword && !!touched.tmpPassword
                              }
                              onChange={(e) => {
                                setFieldValue('tmpPassword', e.target.value);
                              }}
                              onBlur={handleBlur}
                              name={field.name}
                              fullWidth
                              id="auth-reset-tmp-password"
                              startAdornment={
                                <InputAdornment position="start">
                                  <LockOutlinedIcon />
                                </InputAdornment>
                              }
                              type="password"
                              inputProps={{
                                maxLength: 30,
                              }}
                            />
                            {!!errors.tmpPassword && !!touched.tmpPassword ? (
                              <FormHelperText error>
                                {errors.tmpPassword}
                              </FormHelperText>
                            ) : null}
                          </>
                        )}
                      </Field>
                    </FormControl>
                  </div>

                  <div className="mb-3">
                    <FormControl className="w-100">
                      <InputLabel htmlFor="auth-reset-new-password">
                        {t('auth.newPassword')}
                      </InputLabel>
                      <Field name="newPassword">
                        {({field}: FieldProps) => (
                          <>
                            <Input
                              autoComplete="off"
                              value={values.newPassword || ''}
                              error={
                                !!errors.newPassword && !!touched.newPassword
                              }
                              onChange={(e) => {
                                setFieldValue('newPassword', e.target.value);
                              }}
                              onBlur={handleBlur}
                              name={field.name}
                              fullWidth
                              id="auth-reset-new-password"
                              startAdornment={
                                <InputAdornment position="start">
                                  <LockOutlinedIcon />
                                </InputAdornment>
                              }
                              type="password"
                              inputProps={{
                                maxLength: 30,
                              }}
                            />
                            {!!errors.newPassword && !!touched.newPassword ? (
                              <FormHelperText error>
                                {errors.newPassword}
                              </FormHelperText>
                            ) : null}
                          </>
                        )}
                      </Field>
                    </FormControl>
                  </div>

                  <div className="mb-3">
                    <FormControl className="w-100">
                      <InputLabel htmlFor="auth-reset-new-password-confirm">
                        {t('auth.newPasswordConfirm')}
                      </InputLabel>
                      <Field name="newPasswordConfirm">
                        {({field}: FieldProps) => (
                          <>
                            <Input
                              autoComplete="off"
                              value={values.newPasswordConfirm || ''}
                              error={
                                !!errors.newPasswordConfirm &&
                                !!touched.newPasswordConfirm
                              }
                              onChange={(e) => {
                                setFieldValue(
                                  'newPasswordConfirm',
                                  e.target.value,
                                );
                              }}
                              onBlur={handleBlur}
                              name={field.name}
                              fullWidth
                              id="auth-reset-new-password-confirm"
                              startAdornment={
                                <InputAdornment position="start">
                                  <LockOutlinedIcon />
                                </InputAdornment>
                              }
                              type="password"
                              inputProps={{
                                maxLength: 30,
                              }}
                            />
                            {!!errors.newPasswordConfirm &&
                            !!touched.newPasswordConfirm ? (
                              <FormHelperText error>
                                {errors.newPasswordConfirm}
                              </FormHelperText>
                            ) : null}
                          </>
                        )}
                      </Field>
                    </FormControl>
                  </div>

                  <div className="text-center">
                    <Button
                      disabled={isSubmitting || isValidating || loading}
                      type="submit"
                      color="primary"
                      variant="contained"
                      size="large"
                      className="my-2"
                      style={{minWidth: '120px'}}>
                      {t('actions.send')}
                    </Button>
                  </div>

                  <div className="mb-3 py-2" style={{minHeight: '20px'}}>
                    {loading ? (
                      <LinearProgress value={100} variant="indeterminate" />
                    ) : null}
                  </div>
                </Form>
              </CardContent>
            </Card>
          );
        }}
      </Formik>
    );
  },
);
Form2.displayName = 'Form2';
