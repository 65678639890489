import React, {FC} from 'react';
import {SyncLoader} from 'react-spinners';

interface Props {
  loading: boolean;
}

export const OverlayLoading: FC<Props> = ({loading}: Props) => {
  if (!loading) {
    return null;
  }
  return (
    <>
      <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
        <div className="d-flex align-items-center flex-column px-4">
          <SyncLoader color="#5383ff" loading />
        </div>
      </div>
    </>
  );
};
