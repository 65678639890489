import React from 'react';

const HeaderMenu = () => {
  return (
    <>
      <div className="app-header-menu" />
    </>
  );
};

export default HeaderMenu;
