import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import {Link} from 'react-router-dom';

import {IconButton, Box, Tooltip} from '@material-ui/core';

import {connect} from 'react-redux';

import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import projectLogo from 'assets/images/profile.png';

import {setSidebarToggleMobile, setSidebarToggle} from 'store/ducks/theme';

const SidebarHeader = ({
  sidebarToggleMobile,
  setSidebarToggleMobileDispatch,
  setSidebarToggleDispatch,
  sidebarToggle,
  sidebarHover,
}) => {
  const toggleSidebar = () => {
    setSidebarToggleDispatch(!sidebarToggle);
  };
  const toggleSidebarMobile = () => {
    setSidebarToggleMobileDispatch(!sidebarToggleMobile);
  };

  return (
    <>
      <div
        className={clsx('app-sidebar-header', {
          'app-sidebar-header-close': sidebarToggle && !sidebarHover,
        })}>
        <Box className="header-logo-wrapper" title="Portal Pagamentos">
          <Link to="/" className="header-logo-wrapper-link">
            <IconButton
              color="primary"
              size="medium"
              className="header-logo-wrapper-btn">
              <img
                className="app-sidebar-logo"
                alt="Portal Pagamentos"
                src={projectLogo}
              />
            </IconButton>
          </Link>
          <Box className="header-logo-text">Desbravador</Box>
        </Box>
        <Box
          className={clsx('app-sidebar-header-btn', {
            'app-sidebar-header-btn-close': sidebarToggle && !sidebarHover,
          })}>
          <Tooltip title="Toggle Sidebar" placement="right">
            <IconButton color="inherit" onClick={toggleSidebar} size="medium">
              {sidebarToggle ? <MenuRoundedIcon /> : <MenuOpenRoundedIcon />}
            </IconButton>
          </Tooltip>
        </Box>
        <Box className="app-sidebar-header-btn-mobile">
          <Tooltip title="Toggle Sidebar" placement="right">
            <IconButton
              color="inherit"
              onClick={toggleSidebarMobile}
              size="medium">
              {sidebarToggleMobile ? (
                <MenuOpenRoundedIcon />
              ) : (
                <MenuRoundedIcon />
              )}
            </IconButton>
          </Tooltip>
        </Box>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  sidebarToggle: state.theme.sidebarToggle,
  sidebarHover: state.theme.sidebarHover,
  sidebarToggleMobile: state.theme.sidebarToggleMobile,
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobileDispatch: (enable) =>
    dispatch(setSidebarToggleMobile(enable)),
  setSidebarToggleDispatch: (enable) => dispatch(setSidebarToggle(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarHeader);

SidebarHeader.propTypes = {
  sidebarToggleMobile: PropTypes.bool.isRequired,
  setSidebarToggleDispatch: PropTypes.func.isRequired,
  setSidebarToggleMobileDispatch: PropTypes.func.isRequired,
  sidebarToggle: PropTypes.bool.isRequired,
  sidebarHover: PropTypes.bool.isRequired,
};
