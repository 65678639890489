import React from 'react';
import PropTypes from 'prop-types';

import {useAuth} from 'context/auth';

import clsx from 'clsx';

import {Avatar, Box} from '@material-ui/core';

import {connect} from 'react-redux';

import avatarDsl from 'assets/images/profile.png';

const SidebarUserBox = ({sidebarToggle, sidebarHover}) => {
  const {user} = useAuth();
  return (
    <>
      <Box
        className={clsx('app-sidebar-userbox', {
          'app-sidebar-userbox--collapsed': sidebarToggle && !sidebarHover,
        })}>
        <Avatar
          alt="avatar"
          src={avatarDsl}
          className="app-sidebar-userbox-avatar"
        />
        <Box className="app-sidebar-userbox-name">
          <Box>
            <b>{user?.fantasia}</b>
          </Box>
          <Box className="app-sidebar-userbox-description">
            {user?.documento}
          </Box>
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarToggle: state.theme.sidebarToggle,
  sidebarHover: state.theme.sidebarHover,
});

export default connect(mapStateToProps)(SidebarUserBox);

SidebarUserBox.propTypes = {
  sidebarToggle: PropTypes.bool.isRequired,
  sidebarHover: PropTypes.bool.isRequired,
};
