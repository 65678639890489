/* eslint-disable no-console */
import {useEffect} from 'react';

import {useAuth} from 'context/auth';

import api, {AxiosError, AxiosResponse} from 'services/apiDslService';
import {useSnackbar} from 'notistack';
import {useTranslation} from 'react-i18next';

export const Interceptor = () => {
  const {user, signOut} = useAuth();
  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation();

  useEffect(() => {
    if (!user) {
      console.log('REMOVE API INTERCEPTOR');
      api.interceptors.response.clear();
    } else {
      console.log('ADD API INTERCEPTOR');
      api.interceptors.response.use(
        (response: AxiosResponse) => {
          return response;
        },
        (error: AxiosError) => {
          if (
            error?.response?.status === 401 ||
            error?.response?.status === 403
          ) {
            console.log('INTERCEPTOR SIGNOUT');

            setTimeout(() => {
              signOut();
            }, 1000);

            enqueueSnackbar(t('auth.sessionExpired'), {
              variant: 'error',
            });
          }

          return Promise.reject(error);
        },
      );
    }

    return () => {
      api.interceptors.response.clear();
    };
  }, [t, enqueueSnackbar, signOut, user]);

  return null;
};
