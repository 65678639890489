/* eslint-disable camelcase */
/* eslint-disable no-console */
import React, {FC, useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {pick} from 'lodash';
import {format, parseISO, differenceInHours, isAfter} from 'date-fns';

import {createStyles, WithStyles, withStyles} from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import {
  Dialog,
  Grid,
  DialogContent,
  DialogActions,
  Typography,
  Theme,
  DialogTitleProps,
  Button,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FileCopySharp from '@material-ui/icons/FileCopySharp';
import Done from '@material-ui/icons/Done';
import Alert from '@material-ui/lab/Alert';
import {Alert as SweetAlert} from 'providers/alert';

import {SyncLoader} from 'react-spinners';
import Lottie from 'lottie-react';

import SuccessGif from 'assets/lottie/success.json';
import {PageError} from 'components/pageError';

import apiDslPay, {AxiosResponse} from 'services/apiDslPay';
import apiDslService from 'services/apiDslService';
import {unformat} from 'helpers/number';

import {useSnackbar} from 'notistack';

import {IInstallment} from 'types';
import PIX_CONFIG from 'config/pix';

type Props = {
  visible: boolean;
  installment: IInstallment | null;
  handleClose: () => void;
  handleConfirm: () => void;
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

interface ICustomTitleDialog {
  onClose: () => void;
}
type CustomDialogTitleProps = WithStyles<typeof styles> &
  DialogTitleProps &
  ICustomTitleDialog;

const DialogTitle = withStyles(styles)(({
  children,
  classes,
  onClose,
  ...other
}: CustomDialogTitleProps) => {
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

interface IPixData {
  access_token: string;
  deep_link: string;
  order_id: string;
  qr_code: string;
  qr_code_text: string;
  status: string;
  wallet: string;
}

interface IPaymentDataDto {
  parcela: Pick<IInstallment, 'valortotal'>;
  order_id: string;
  chave: string;
}

interface IPixAuth {
  access_token: string;
}

const PIX_GET_STATUS_TIMEOUT = 3000;

export const ModalPix: FC<Props> = ({
  visible,
  installment,
  handleClose,
  handleConfirm,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [pixData, setPixData] = useState<IPixData | null>(null);
  const [authToken, setAuthToken] = useState<string | null>(null);
  const [paymentDone, setPaymentDone] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();

  const copyToClipboard = () => {
    const text = pixData?.qr_code_text;
    if (text) {
      navigator.clipboard.writeText(text).then(
        () => {
          enqueueSnackbar(t('finance.copySuccess'), {
            variant: 'info',
          });
        },
        () => {
          enqueueSnackbar(t('finance.copyError'), {
            variant: 'error',
          });
        },
      );
    }
  };

  const handlePressCloseButton = () => {
    console.log('CLOSE BUTTON');
    if (paymentDone && !sending && !error) {
      handleConfirm();
    } else if (!loading && !sending && !paymentDone) {
      if (pixData && authToken) {
        // const {order_id} = pixData;

        SweetAlert(
          {
            title: '',
            text: t('finance.questionCancelPayment'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t('actions.yes'),
            cancelButtonText: t('actions.no'),
          },
          (alertResult) => {
            if (alertResult.isConfirmed) {
              // apiDslPay
              //   .delete(`/pix/pix-dsl/${order_id}/${authToken}`)
              //   .then((response) => {
              //     const {status} = response.data;
              //     console.log(`ORDER STATUS - ${order_id}:`, status);
              //   })
              //   .catch((e) => {
              //     console.error(e);
              //   });

              handleClose();
            }
          },
        );
      }
    } else if (error) {
      handleClose();
    }
  };

  useEffect(() => {
    let timerId: ReturnType<typeof setInterval>;
    if (visible && installment && pixData && authToken) {
      timerId = setInterval(() => {
        console.log('WAITING PAYMENT');

        const controller = new AbortController();
        apiDslPay
          .get(`/pix/pix-dsl/${pixData.order_id}/${authToken}`, {
            signal: controller.signal,
          })
          .then((response) => {
            const {status, order_id} = response.data;
            console.log(`ORDER STATUS - ${order_id}:`, status);

            if (status === 'approved') {
              setPaymentDone(true);
              setSending(true);
              console.log('ORDER DONE', order_id);

              const paymentData: IPaymentDataDto = {
                parcela: pick(installment, ['valortotal', 'valorpagamento']),
                chave: installment.chave,
                order_id,
              };

              apiDslService
                .post('/setpix/', paymentData)
                .then(() => {
                  setSending(false);
                })
                .catch(() => {
                  setSending(false);
                  setError(true);
                });
            } else if (status === 'pending') {
              // KEEP WAITING
            } else if (status === 'cancelled') {
              clearInterval(timerId);
            }
          })
          .catch((e) => {
            console.error(e);
          });
      }, PIX_GET_STATUS_TIMEOUT);
    }
    return () => clearInterval(timerId);
  }, [visible, installment, pixData, authToken, handleConfirm]);

  const generateQRCode = useCallback(
    (shipayAuthToken: string) => {
      if (installment && visible) {
        const data = {
          order_ref: `ref_pix_${installment.chave}`,
          total: unformat(installment.valortotal),
          access_token: shipayAuthToken,
          callback_url: `https://servicos.desbravador.com.br/portal/data/administrativo/view/modules/payments/api/callback/?chave=${installment.chave}`,
        };

        apiDslPay
          .post('/pix/pix-dsl', data)
          .then((response: AxiosResponse<IPixData>) => {
            setLoading(false);
            setPixData(response.data);
          })
          .catch((e) => {
            setLoading(false);
            setError(true);

            if (
              e?.response?.status === 401 ||
              e?.response?.status === 403 ||
              e?.response.status === 422
            ) {
              window.localStorage.removeItem(`@DSL_PAYMENT_SHIPAY:token`);
              window.localStorage.removeItem(`@DSL_PAYMENT_SHIPAY:datetime`);
            }
          });
      }
    },
    [installment, visible],
  );

  useEffect(() => {
    if (visible && installment && !pixData) {
      setLoading(true);
      setError(false);
      setPaymentDone(false);
      setSending(false);

      const storagedShipayToken = window.localStorage.getItem(
        `@DSL_PAYMENT_SHIPAY:token`,
      );
      const storagedShipayDatetime = window.localStorage.getItem(
        `@DSL_PAYMENT_SHIPAY:datetime`,
      );

      let diffInHours = 9999;
      let dateIsAfter = false;

      if (storagedShipayDatetime) {
        diffInHours = differenceInHours(
          new Date(),
          parseISO(storagedShipayDatetime),
        );
        dateIsAfter = isAfter(new Date(), parseISO(storagedShipayDatetime));
      }

      if (
        storagedShipayToken &&
        storagedShipayDatetime &&
        dateIsAfter &&
        diffInHours <= 4
      ) {
        setAuthToken(storagedShipayToken);
        generateQRCode(storagedShipayToken);
      } else {
        apiDslPay
          .post('/pix/pix-dsl-auth', {
            access_key: PIX_CONFIG.access_key,
            secret_key: PIX_CONFIG.secret_key,
            client_id: PIX_CONFIG.client_id,
          })
          .then((authResponse: AxiosResponse<IPixAuth>) => {
            const {access_token} = authResponse.data;

            window.localStorage.setItem(
              '@DSL_PAYMENT_SHIPAY:token',
              access_token,
            );
            window.localStorage.setItem(
              '@DSL_PAYMENT_SHIPAY:datetime',
              format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            );

            setAuthToken(access_token);
            generateQRCode(access_token);
          })
          .catch(() => {
            setLoading(false);
            setError(true);
          });
      }
    } else if (!visible) {
      setPixData(null);
    }
  }, [visible, installment, pixData, generateQRCode]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      scroll="body"
      open={visible}
      onClose={(event, reason) => {
        if (!reason) {
          handlePressCloseButton();
        }
      }}>
      <Grid container spacing={0}>
        <Grid item xs={12} lg={12}>
          <DialogTitle
            id="customized-dialog-title"
            onClose={() => {
              handlePressCloseButton();
            }}
          />

          <DialogContent
            className="d-flex flex-column justify-content-center align-items-center"
            style={{padding: 0, minHeight: '430px'}}>
            {error && !loading ? <PageError /> : null}

            {loading && !error ? (
              <div className="d-flex align-items-center flex-column px-4 py-4">
                <SyncLoader color="#5383ff" loading />
                <Typography variant="body1">
                  {t('finance.generatingQRCode')}
                </Typography>
              </div>
            ) : null}

            {!loading && !error ? (
              <>
                {pixData && !paymentDone ? (
                  <>
                    <Typography variant="h5">{t('finance.pixScan')}</Typography>
                    <Typography variant="body1">
                      {t('finance.pixScan2')}
                    </Typography>
                    <img
                      className="w-50 mx-auto d-block my-1 img-fluid"
                      alt="pix-qrcode"
                      src={pixData?.qr_code}
                    />
                    <Typography variant="h6" className="mb-2">
                      {t('finance.pixWaitMessage')}
                    </Typography>
                  </>
                ) : null}

                {paymentDone ? (
                  <>
                    <Lottie
                      animationData={SuccessGif}
                      loop={false}
                      style={{height: '200px'}}
                    />
                    <div style={{minHeight: '140px'}}>
                      {sending ? (
                        <SyncLoader color="#5383ff" loading />
                      ) : (
                        <>
                          <Alert severity="success">
                            {t('finance.paymentDone')}
                          </Alert>
                          <div className="p-4">
                            <Button
                              variant="outlined"
                              color="primary"
                              startIcon={<Done />}
                              onClick={() => {
                                handleConfirm();
                              }}>
                              {t('actions.close')}
                            </Button>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                ) : null}

                {pixData?.qr_code_text && !paymentDone ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<FileCopySharp />}
                    onClick={() => {
                      copyToClipboard();
                    }}>
                    {t('finance.pixCopyPaste')}
                  </Button>
                ) : null}
              </>
            ) : null}
          </DialogContent>
          <DialogActions />
        </Grid>
      </Grid>
    </Dialog>
  );
};
